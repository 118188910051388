#custom-input {
    &.input_wrapper {
        position: relative;

        @media (max-width: $sm) {
            width: 100%;
            flex: 1;

            .custom_input {
                width: 100%;
            }
        }

        &--x2s {
            .custom_input {
                width: 3rem;
            }
        }

        &--xxs {
            .custom_input {
                width: 4rem;
            }
        }

        &--xs {
            .custom_input {
                width: 6rem;
            }
        }

        &--sm {
            .custom_input {
                width: 8rem;
            }
        }

        &--md {
            .custom_input {
                width: 10rem;
            }
        }

        &--lg {
            .custom_input {
                width: 12rem;
            }
        }

        &--full {
            width: 100%;
            flex: 1;

            .custom_input {
                width: 100%;
            }
        }

        .custom_input {
            margin: 0;
            padding: 6px;
            border: 0;
            background-color: $white;
            border-radius: 6px;
            font-size: 16px;
            color: $color8282;

            &--25:not(.no-xs) {
                padding-right: 2.5rem;
            }

            &--2 {
                padding-right: 2rem;
            }

            &--4 {
                padding-right: 4rem;
            }

            @media (max-width: $sm) {
                width: 100%;
            }
        }

        .input_inner {
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;

            span {
                color: $color717;
                margin-right: .5rem;
            }
        }

        &--combined {
            display: flex;
            width: 100%;
            flex: 1;

            @media (min-width: $xl) {
                &--xs {
                    max-width: 6rem;
                }

                &--sm {
                    max-width: 8rem;
                }

                &--md {
                    max-width: 10rem;
                }

                &--lg {
                    max-width: 12rem;
                }
            }

            .custom_input {
                width: 100%;

                @media (min-width: $xl) {
                    &--xs {
                        max-width: 6rem;
                    }

                    &--sm {
                        max-width: 8rem;
                    }

                    &--md {
                        max-width: 10rem;
                    }

                    &--lg {
                        max-width: 12rem;
                    }
                }
            }
        }
    }
}
