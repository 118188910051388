.loader_container {
    display: flex;
    justify-content: center;
    align-items: center;

    &--absolute {
        width: 100%;
        position: absolute;
        z-index: 1;
    }

    &--overlay + div {
        filter: brightness(.85) blur(2px);
    }

    .loader {
        width: 75px;
        height: 75px;
    }
}
