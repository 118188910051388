#custom-breadcrumb {
    width: 100%;

    .breadcrumb_parent {
        color: $blue-light;
        font-family: $lato-bold;
        margin: 0;
        font-size: 24px;
    }

    svg {
        vertical-align: bottom;
    }

    .breadcrumb_title {
        color: $blue-light;
        font-family: $lato;
        margin: 0;
        font-size: 24px;
        cursor: unset;
    }
}
