#custom_select {
    &.custom_select {
        margin: 0;
        padding: 6px;
        border: 0;
        background: $white;
        border-radius: 6px;
        font-size: 16px;
        color: $color8282;

        option {
            background-color: $white;
        }

        &--sm {
            width: 8rem;
        }

        &--md {
            width: 10rem;
        }

        &--lg {
            width: 12rem;
        }

        @media (max-width: $sm) {
            width: 100%;
        }
    }
}
