// based on http://brenna.github.io/csshexagon/

%hexagon {
    position: relative;
    width: #{$width}px;
    height: #{$height}px;
    background-color: $white;

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 0;
        border-left: #{$width/2}px solid transparent;
        border-right: #{$width/2}px solid transparent;
    }

    &::before {
        bottom: 100%;
        border-bottom: #{$height/2}px solid $white;
    }

    &::after {
        top: 100%;
        width: 0;
        border-top: #{$height/2}px solid $white;
    }
}
