.floating-input {
    display: flex;
    width: 100%;
    height: 56px;
    border-radius: 4px;
    border: none;
    padding: 10px;
    font-size: 16px;
    margin-top: 40px;
    background-color: #ffffffb3;

    @media (max-width: 1024px) {
        margin-top: 32px !important;
    }
}

.blue-text{
    color: blue !important
}

.width-full{
    width: 100%;
    max-width: 480px;
}