#instructions {
  background-color: $white;
  align-items: center;

  @media (max-width: $sm){
    align-items: flex-start;
  }

  justify-content: center;
  display: flex;
  min-height: 100vh;
  .popup-container {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 14px;
    // width: 85vmin;
    width: 100vmin;
    background-color: #fff;
    justify-self: center;
    position: relative;
    // height: 78vmin;
    // height: unset;
    padding-bottom: 10px;

    @media (max-width: $sm) {
      margin: 10px;
    }

    .closeImage {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      padding-top: 5px;
    }
    .carousel_instsructions {
      display: flex;
      margin-top: 25px;

      justify-content: center;
      .inner_carousel_instaructions {
        width: 80vmin;
      }
      .carousel_image {
        width: 100%;
        height: 49vmin;
      }
      .carousel-indicators {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 #{-$spaceY} 0;
        @media (max-width: $sm), (max-width: $xs) {
        }
        & > * {
          border-radius: 50%;
          width: 5px;
          height: 5px;
          background-color: #505b6b;
          margin-left: 28px;
          @media (max-width: $sm), (max-width: $xs) {
            margin-left: 10px;
          }
          &.active {
            width: 12px;
            height: 12px;
            @media (max-width: $sm), (max-width: $xs) {
            }
          }
        }
      }
      .carousel-control-prev {
        // width: $spaceX;
        width: 2rem;
        left: -$spaceX;
        opacity: 1;
        box-shadow: none;
        @media (max-width: $sm), (max-width: $xs) {
          width: 2rem;
          left: -2rem;
        }
      }

      .carousel-control-next {
        // width: $spaceX;
        width: 2rem;
        right: -$spaceX;
        opacity: 1;
        box-shadow: none;
        @media (max-width: $sm), (max-width: $xs) {
          width: 2rem;
          right: -2rem;
        }
      }
    }
  }
  .img_caption{
    color:#537bb8
  }
  .carousel-caption {
    position: unset;
    right: unset;
    bottom: unset;
    left: unset;
    z-index: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    color: #537bb8;
    
    // bottom: 260px;
  }
  .carousel-caption h2{
    font-size: 12px;
  }
  .capturingBtn{
    display: inline-block;
    width: 100%;
    text-align: right;
    margin-top: 50px;
    padding-right: 10px;
  }
  .capturing_btn{
    color: #fff;
    background-color: #6a9eea;
    border: 0;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 7px;
    padding: .5rem 1rem;
    font-family: "Roboto Medium";
    display: unset !important;
    align-items: center;
  }
}
