#allroom {
  .middl-claim {
    // padding-left: 230px !important;
    padding-left: 150px !important;

    @media only screen and (min-width: 576px) and (max-width: 768px) {
      padding-left: 0 !important;
    }
  }

  margin-top: -30px;

  @media only screen and (min-width: 576px) and (max-width: 768px) {
    margin-top: 0;
  }

  padding: 2rem 2rem 1rem 2rem;
  min-height: calc(100vh - 5rem);
  background-color: #fff;

  #form_group .input_wrapper .variable_input--sm {
    width: 100%;
  }

  .table-curved {
    border-collapse: separate;
    border-spacing: 0 1em;
    // font-family: $roboto-bold;
    font-family: $roboto;
    // color: #b8b9bc;
    color: #415878;

    th {
      padding: 1rem;
      color: #6a9eea;
      border: 0;
      padding-left: 0;

      p {
        cursor: pointer;
        margin-bottom: -15px;
      }
    }

    tr {
      z-index: 0;
    }
    tr:hover td {
      background-color: #dcdee1;
      cursor: pointer;
      box-shadow: none;
    }
    .activeTD {
      background-color: #6a9eea;
      b,
      svg,
      p,
      span {
        color: #fff;
      }
    }
    tr:hover .activeTD {
      background-color: #6a9eea;
      cursor: pointer;
      box-shadow: none;
    }
    td {
      position: relative;
      background-color: #e7ecf6;
      border-bottom: 0;
      border-top: 0;
      // padding: 0.5rem 0 0.5rem 1rem;
      b {
        font-weight: bold;
        color: #000;
      }

      td:first-child {
        border-radius: 15px 0 0 15px;
      }

      td:last-child {
        border-radius: 0 15px 15px 0;
        padding: 3px;
      }

      .greenText {
        color: #529d2a !important;
        font-weight: bold;
      }

      /* box-shadow: 2px 3px 2px #00000029;
        -webkit-box-shadow: 2px 3px 2px #00000029;
        -moz-box-shadow: 2px 3px 2px #00000029; */
      p {
        margin-top: 1rem;
        margin-bottom: 0;
        color: #000;
      }
      svg {
        color: #415878;
      }

      img {
        border-radius: 8px;
        width: 85px;
        height: 60px;
        margin: 5px;
      }
    }
    @media only screen and (max-width: 500px) {
      zoom: calc(100% / 2.2);
    }

    .fixedRight {
      @media (min-width: $md) {
        right: 0;
        // position: fixed;
        margin-top: calc(15vh);
      }

      @media only screen and (min-width: 576px) and (max-width: 768px) {
        position: relative;
        margin-top: 10px;
        width: 100%;
        display: block;
        text-align: center;
        max-width: 100%;
        right: unset;
      }
    }
  }

  .fixedRight {
    @media (min-width: $md) {
      right: 0;
      // position: fixed;
      margin-top: calc(15vh);
    }

    @media only screen and (min-width: 576px) and (max-width: 768px) {
      position: relative;
      margin-top: 10px;
      width: 100%;
      display: block;
      text-align: center;
      max-width: 100%;
      right: unset;
    }
  }

  #ClaimsPagination {
    .pagination {
      float: right;
    }
  }

  #btnSection {
    @media only screen and (min-width: 576px) and (max-width: 768px) {
      // width: 150%;
    }

    .btn_hover_text {
      display: none;
    }

    .new_claims_btn {
      font-size: 25px;
      padding: 1.4rem;
      width: 100%;
      display: block;
      margin-left: 0;

      @media only screen and (min-width: 576px) and (max-width: 768px) {
        width: 100%;
        margin-left: 0;
      }
    }

    .new_claims_btn:hover + .btn_hover_text {
      display: block !important;
    }
  }
}

.kaamc {
  color: #000;
  font-family: $roboto;
}

select {
  font-family: $lato !important;
}

.claimIDHeader{
  position: absolute;
    margin-top: -50px;
    color: #ffffff;
    margin-left: 20px;
}