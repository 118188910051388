%brokenList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color70;
    position: relative;
    margin-left: 1.2rem;

    p {
        margin: 0;
        font-size: 22px;
        color: $black;
        font-family: $lato;
    }

    .broken_list {
        &_title_wrapper {
            display: flex;
        }

        &_arrow {
            position: absolute;
            top: 50%;
            left: -33px;
            transform: translateY(-50%);
            color: $color82;
            background-color: $white;
        }

        &_title {
            //width: 12rem;
            width: 15ch;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-transform: capitalize;
        }
        &_title:hover{
    overflow: visible;
    z-index: 11;
    white-space: normal; 
}

        &_select_text {
            width: 4.5rem;
        }

        &_select {
            width: 4.5rem;
            padding: 0;
            background: $colorE7;
            border-radius: 6px;
            font-size: 16px;
            color: $black;
            margin: 0 0 2px;
            border: 0;

            &_option {
                background-color: $white;
            }
        }

        &_quantity {
            width: 3ch;
        }

        &_icon {
            width: .75rem;
        }
    }
}

// noInspection Stylelint
#broken-list,
.recursive_broken_list_container {
    .broken_list_container {
        &:not(:first-child) {
            margin-top: .5rem;
        }

        // used in Details.scss for wall
        .broken_list_header {
            @extend %brokenList;

            //&_active.broken_list_header_active_collapsed.broken_list_header_active_variables {
            &_active.broken_list_header_active_collapsed {
                background-color: $color680;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                border-bottom: 0;
                padding: 5px 0 5px 5px;
            }
        }

        .broken_list_sub_details {
            .recursive_broken_list_container {
                &:not(:last-child) {
                    margin-bottom: .5rem;
                }

                .broken_list_header {
                    border-bottom: 0;
                }

                &.recursive_broken_list_container_has_children {
                    margin-left: 26px;
                }
            }
        }
    }

    &.recursive_count {
        @for $count from 1 through 5 {
            &_#{$count} {
                .recursive_broken_list_container_has_children {
                    .broken_list_title {
                        width: calc(15ch - #{$count * 2}ch);
                    }
                }
            }
        }
    }
}

.error {
    color: red;
}

.sub_details--buttons_information_wrapper {
    display: flex;
    justify-content:flex-end;
    .temp_buttons {
        margin: 0;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.sub_details--read_information_wrapper {
    padding-top: 0 !important;
}
