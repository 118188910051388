#form_group {
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: .6rem;
    }

    & > p {
        min-width: 52%;
        margin: 0;
        color: $color717;
        font-size: 18px;
    }

    @media (max-width: $sm), (min-width: $md) and (max-width: $lg) {
        flex-direction: column;
        align-items: flex-start;

        & > p {
            margin-bottom: .5rem;
        }
    }

    .input_wrapper {
        position: relative;

        @media (max-width: $sm), (min-width: $md) and (max-width: $lg) {
            width: 100%;
            flex: 1;

            .variable_input {
                width: 100%;
            }
        }

        &--full {
            width: 100%;
            flex: 1;

            .variable_input {
                width: 100%;
            }
        }

        .variable_input::placeholder {
            color: $color82;
        }

        .variable_input {
            margin: 0;
            padding: 6px;
            border: 1px solid $color717;
            background: $colorDC;
            border-radius: 6px;
            font-size: 16px;
            color: $color717;

            &--sm {
                width: 8rem;
            }

            &--lg {
                width: 12rem;
            }

            &--2 {
                padding-right: 2rem;
            }

            &--4 {
                padding-right: 4rem;
            }

            @media (max-width: $sm), (min-width: $md) and (max-width: $lg) {
                width: 100%;
            }
        }

        .input_inner {
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;

            span {
                color: $color717;
                margin-right: .5rem;
                font-size: 16px;
            }
        }
    }

    .flex_wrapper {
        display: flex;
        width: 100%;

        .input_wrapper {
            display: flex;
            width: 100%;
            flex: 1;

            @media (min-width: $xl) {
                &--sm {
                    max-width: 8rem;
                }

                &--lg {
                    max-width: 12rem;
                }
            }

            .variable_input {
                width: 100%;

                @media (min-width: $xl) {
                    &--sm {
                        max-width: 7rem;
                    }

                    &--lg {
                        max-width: 12rem;
                    }
                }
            }
        }
    }
}
