/* Font  */
$primary: #6a9eea;
$secondary: #6895eb;

$blue-light: #3d69aa;
$header: #537bb8;
$tomato: #d97062;
$light-tomato: #ff000026;
$green: #5aac62;
$light-green: #00ff194c;
$yellow: #eab54d;
$light-yellow: #eab54d4d;

$white: #fff;
$black: #000;

/* Scroll */
$scroll-background: transparent;

/* Font Family */
$roboto-light: 'Roboto Light';
$roboto: 'Roboto';
$roboto-medium: 'Roboto Medium';
$roboto-bold: 'Roboto Bold';
$lato: 'Lato';
$lato-italic: 'Lato Italic';
$lato-bold: 'Lato Bold';

/* SPECIFIC */
$color30: #306ed0;
$color31: #316fd0;
$color4D: #4d89f5;
$colorEC: #eceff6;
$colorE7: #e7ecf6;
$color70: #707070;
$colorE9: #e9e9e9;
$colorF6: #f6faff;
$colorDC: #dcdcdc80;
$colorF5: #f5f7fa;
$color82: #8282828e;
$color8282: #828282;
$color680: #6800001f;
$color029: #00000029;
$color099: #0009;
$color828: #82828214;
$color717: #717d88f9;
$colorFFF: #ffffffb2;
$color304: #30486b;
$color50: #505b6b;

/* Breakpoints */
$xs: 400px;
$sm: 400px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$xxxl: 1600px;
