#forgotpassword {
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  .bgImg {
    color: #fff;
    min-height: 100vh;
    width: 100vw;
    position: fixed;
    background-image: url('../../../images/backgroundImg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-filter: blur(7px);
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .inner-div {
    z-index: 1000;
    //margin-top: 15%;
    padding: 50px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;

    .main-logo-container {
      align-items: center;
      justify-content: center;
      display: flex;

      img {
        width: 150px;
        height: auto;
      }
    }

    .logo-name {
      align-items: center;
      justify-content: center;
      display: flex;
      padding-bottom: 60px;
      padding-top: 10px;

      img {
        width: 348px;
        height: 55px;
      }
    }

    .floating-input-div {
      max-width: 578px;
      min-width: 230px;
      width: 50%;

      @media (max-width: $sm) {
        width: 100px !important;
      }

      @media (max-width: $md) {
        width: 60% !important;
      }
    }

    .trailIcon {
      position: relative;
      top: -39px;
      float: right;
      padding-right: 10px;
    }

    .textLink {
      color: #3d69aa;
      font-size: 12px;
      padding-left: 10px;
      margin-top: 4px;
      width: fit-content;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;

      .newButton {
        width: 225px;
        font-family: $roboto-medium;
        font-size: 16px;
        background-color: #6a9eea;
        border: none;
        margin-top: 45px;
        margin-bottom: 20px;
        box-shadow: 0 3px 6px #00000029;
        border-radius: 7px;
      }
      .backButton {
        width: 225px;
        font-family: $roboto-medium;
        font-size: 16px;
        background-color: #6a9eea;
        border: none;
        box-shadow: 0 3px 6px #00000029;
        border-radius: 7px;
      }
    }
  }

  .ErrorDivEmail {
    color: red;
  }
  .createAcc {
    font-size: 20px;
    display: flex;
    justify-content: center;
    color: white;
    .createAccTxt {
      color: white;
      cursor: pointer;
    }
  }

  .passwordCheckcointener {
    color: #3169b0;
    margin-top: 1rem;
  }

  .must-text {
    width: 100%;
    padding: 0.2rem;
    font-weight: 900;
  }

  .cross-out {
    color: green;
    /*list-style-image: url(https://img.icons8.com/color/18/checkmark.png);*/
  }
}