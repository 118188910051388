#interface-upload {
    margin-top: 2rem;

    .upload_box {
        padding: 10px 4px 4px;

        .images_box {
            position: relative;
        }

        .carouselUl {
            li {
                padding: 3px;
            }
        }

        .carousel-button-group {
            .leftArrow {
                position: absolute;
                left: -2rem;
                top: 50%;
                transform: translateY(-50%);
            }

            .rightArrow {
                position: absolute;
                right: -2rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
