#custom-box {
    width: 100%;
    height: 100%;

    .box_layout {
        background-color: $white;
        box-shadow: 0 3px 6px $color029;
        border-radius: 9px;
        height: 100%;
    }

    .content_box {
        padding: 1rem;
    }

    .custom_header_box {
        .header {
            box-shadow: 0 3px 6px $colorE9;
            border-radius: 9px;
            background-color: $colorF5;
            padding: .5rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-transform: uppercase;

            p {
                margin: 0;
                color: $primary;
                font-size: 20px;
            }
        }
    }
}
