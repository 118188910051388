#interface-calculation {
    .calculation_box {
        display: flex;
        flex-direction: column;

        .calculation_wrapper {
            display: flex;
            flex-direction: column;
            // height: 250px;
            height: auto;
            overflow-x: scroll;

            #broken-list {
                .broken_list_header {
                    margin-left: 0;
                    overflow: auto;
                    cursor: pointer;
                    padding: 5px;

                    &.broken_list_header_active {
                        &.broken_list_header--walls {
                            background-color: $light-green;
                        }

                        &.broken_list_header--floors {
                            background-color: $light_yellow;
                        }
                    }
                }
            }

            .calculation_details {
                border-bottom: 1px solid $color70;

                p {
                    font-size: 22px;
                        width: 100%;
                        margin-top:5px;
                }
            }
        }

        .calculation_bottom {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 2rem;

            p {
                margin: 0;
                font-family: $lato-bold;
                font-size: 20px;
                color: $color8282;
                text-decoration: underline;
            }

            .clear_list {
                cursor: pointer;
            }

            .btn_wrapper {
                display: flex;

                .calculation_button {
                    margin-left: 1rem;
                    padding: .25rem 1.5rem;

                    p {
                        color: $white;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
