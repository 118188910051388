#carousel-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;

    &>* {
        width: 50%;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $colorE7;
        position: relative;

        p {
            margin: 0;
            font-size: 20px;
            text-align: center;
            font-family: $lato;
            color: $color30;
            text-transform: uppercase;

            @media (max-width: $xs) {
                font-size: 1rem;
            }
        }
    }

    .custom_tab_active {
        font-family: $lato;
        background-color: $secondary;

        p {
            color: $white;
        }
    }

    .images_tab {
        border-radius: 10px 0 0 0;
        width: 20%;

        &.full-width {
            width: 40%;
        }
    }

    .damage_tab {
        width: 20%;
    }

    .floor_tab {
        border-radius: 0 10px 0 0;
        width: 35%;

        svg {
            padding-left: .25rem;
            height: 22px;
            width: 24px;
            margin-bottom: 3px;

        }
    }

    .photos_tab {
        border-radius: 0 10px 0 0;
        width: 25%;
        padding: 5px;
    }
}