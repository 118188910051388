#new-room {
  background-color: $white;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 100vh;
  position: relative;

  .main-container {
    box-shadow: 0 3px 6px $color029;
    border-radius: 14px;
    width: 550px;
    background-color: $white;
    justify-self: center;
    position: relative;

    @media (max-width: $md) {
      width: 500px;
    }

    @media (max-width: $sm), (max-width: $xs) {
      width: 360px;
      height: 740px;
    }

    .closeImage {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      padding-top: 5px;
    }

    .popup-container {
      padding: 0 0 14px 25px;
      margin-bottom: 45px;

      @media (max-width: $sm), (max-width: $xs) {
        padding: 0 0 14px 18px;
      }

      .heading-labels {
        color: $header;
        font-size: 30px;
        font-family: $lato-bold;

        @media (max-width: $sm), (max-width: $xs) {
          font-size: 45px;
        }
      }

      .sub-labels {
        font-size: 15px;
        font-family: $lato;
        color: $header;

        @media (max-width: $sm), (max-width: $xs) {
          font-size: 25px;
        }
      }

      .select-input {
        width: 130px;
        height: 40px;
        align-items: center;
        background-color: $colorE7;
        border-radius: 6px;
        border: none;
        padding-left: 5px;
        color: $black;
        font-size: 23px;

        option {
          color: $black;
        }

        @media (max-width: $sm), (max-width: $xs) {
          width: 168px;
          font-size: 28px;
        }
      }

      .large-select-input {
        width: 250px;
      }

      .sub-labels-link {
        font-size: 12px;
        font-family: $lato-bold;
        color: $header;
        margin-bottom: 20px !important;
        padding-bottom: 13px;

        @media (max-width: $sm), (max-width: $xs) {
          font-size: 18px;
        }
      }

      .roomtypeselect {
        margin-top: 10px;
        margin-bottom: 21px;
      }

      .dropdownImgBox {
        border-radius: 8px;
        width: 94%;
        height: 235px;
        padding-right: 27px;
        background-color: $colorE7;
        border: 1px solid $color70;

        @media (max-width: $sm), (max-width: $xs) {
          background-color: $white;
        }
      }

      .dropdownImgBoxChange {
        width: 94%;
        height: 235px;
        padding-right: 27px;
        background-color: $white;
        border: 1px solid $color70;
        border-radius: 11px;
      }
    }

    .go_back {
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-bottom: 25px;
      cursor: pointer;

      @media (max-width: $sm), (max-width: $xs) {
        display: none;
      }
    }

    .newroom_btn {
      display: block;
      position: absolute;
      right: 31px;
      bottom: 12px;
      padding: 0.5rem 4rem !important;

      @media (max-width: $sm), (max-width: $xs) {
        right: 66px;
        bottom: 20px;
        padding: 1rem 6rem !important;
      }
    }

    .thumbsCont {
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap;
      margin-top: 10px;
      width: 100%;
      margin-left: 20px;

      @media (max-width: $md) {
        overflow: scroll;
        height: 233px;
        width: 438px;
        margin-left: -2px;
      }

      @media (max-width: $sm), (max-width: $xs) {
        width: 310px;
      }
    }

    .thumb {
      display: inline-flex;
      margin-bottom: 8px;
      margin-right: 8px;
      width: 100px;
      height: 100px;
      padding: 4px;
      box-sizing: border-box;
      position: relative;
      z-index: 1000;

      @media (max-width: $sm), (max-width: $xs) {
        width: 87px;
      }
    }

    .thumbInner {
      display: flex;
      min-width: 0;
      overflow: hidden;
    }

    .uploadedimg {
      display: flex;
      width: 100%;
      height: 100%;
      z-index: 100;
      position: relative;
      border-radius: 6px;
    }

    .errorDiv {
      color: red;
      margin-top: 17px;

      @media (max-width: $sm), (max-width: $xs) {
        margin-top: 0;
      }
    }

    .closeImgIcon {
      z-index: 101;
      position: absolute;
      right: 5px;
      top: 100px;
      background: white;
      border-radius: 50%;
    }

    .dragDropTxt {
      position: absolute;
      min-height: 235px;
      /* top: 56px; */
      bottom: 100px;
      width: 81%;
      display: flex;
      align-items: center;

      @media (max-width: $sm), (max-width: $xs) {
        display: none;
      }
    }
  }

  .mobileviewTxt {
    display: none;

    @media (max-width: $sm), (max-width: $xs) {
      display: block;
      position: absolute;
      right: 8rem;
      font-size: 18px;
      color: #717d88f9;
      bottom: 200px;
    }
  }
}
.carousel-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;
  // background-color: black;
  li {
    background-color: #6a9eea;
  }
}
.carousel-control-prev {
  width: 2rem;
  // left: -$spaceX;
  opacity: 1;
  box-shadow: none;
  @media (max-width: $sm), (max-width: $xs) {
    width: 2rem;
    left: -1rem;
  }
}
.carousel-control-next {
  width: 2rem;
  // right: -$spaceX;
  opacity: 1;
  box-shadow: none;
  @media (max-width: $sm), (max-width: $xs) {
    width: 2rem;
    right: -1rem;
  }
}
.img_caption {
  color: #537bb8;
}
.carousel-caption {
  position: unset;
  right: unset;
  bottom: unset;
  left: unset;
  z-index: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #537bb8;

  // bottom: 260px;
}
.carousel-caption h2 {
  font-size: 12px;
}
.capturingBtn {
  display: inline-block;
  width: 100%;
  // text-align: right;
  text-align: center;
  margin-top: 20px;
  padding-right: 10px;
}
.capturing_btn {
  color: #fff;
  background-color: #6a9eea;
  border: 0;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 7px;
  padding: 0.5rem 1rem;
  font-family: 'Roboto Medium';
  display: unset !important;
  align-items: center;
}
.carousel_image {
  width: 100%;
}

.modal-bgcolor-white {
  background-color: white;
}
.modal-border-gray {
  border: 1px solid gray;
  border-radius: 10px;
}

.item-center {
  display: flex;
  height: 100vh;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.success {
  color: $header;
  font-size: 30px;
  font-family: $lato-bold;
}
