.imageUploadDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 35%;
    margin: auto;
}
.imageShowDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 35%;
    margin: auto;
}
.uploadImageDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    border: 1px dotted;
    border-radius: 5px;
    width: 310px;
    height: 200px;
    margin: 20px;
    margin-top: 0 !important;
}
.uploadImageDiv p {
    font-family: "WorkSans-Regular" !important;
}
.uploadImageTitle{
    /* margin-left: 125px !important;
    margin-right: 125px !important; */
    padding-right: 0px !important;
    text-align: center;
    font-size: 28px !important;
    color: #2E3949 !important;
    font-family: "WorkSans-Bold" !important;
    display: flex;
    justify-content: center;
    width: 40%;
    margin: auto;
    padding-top: 2%;
}
.uploadImageSubTitle{
    /* margin-left: 125px !important;
    margin-right: 125px !important; */
    padding-right: 0px !important;
    text-align: center;
    color: #616F83 !important;
    font-family: "WorkSans-Medium" !important;
    display: flex;
    justify-content: center;
    width: 40%;
    margin: auto;
}
.uploadImageSubTitle p{
    font-size: 18px;
}
.ImageUploadBg{
    display: flex !important;
    justify-content: center !important;
    margin-left: 0px !important;
    width: 100% !important;
}
.image{
    margin: 0px !important;
    border-radius: 5px;
}
.uploadedimage{
    width: 310px !important;
    height: 200px !important ;
    border-radius: 5px !important;
    margin: 20px;
    object-fit: contain;
    /* margin-right: 0 !important; */
    margin-top: 0 !important;
        /* border: 0.02px solid #6a9eea; */
        /* margin-bottom: 5px; */
}
.CenterText{
    text-align: center;
    font-size: 18px;
    color: #616F83;
    font-family: "WorkSans-Regular" !important;
}
.dotsDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.dot{
    margin: 0px 8px;
    padding: 4px;
    height: 4px;
    width: 4px;
    background: #D4E0F3 ;
    border-radius: 50px;
}
.activeDot{
    margin: 0px 8px;
    padding: 4px;
    height: 4px;
    width: 4px;
    background: #6A9EEA ;
    border-radius: 50px;
}
.centerDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.imageUploadButton {
    color: #fff;
    background-color: #6a9eea;
    border: 0;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 7px;
    padding: 0.5rem 1rem;
    font-family: "WorkSans-Medium";
    width: 280px;
}
.imageUploadSecondaryButton{
    color: #6a9eea;
    background-color: transparent;
    border: 1px solid #6a9eea;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 7px;
    padding: 0.5rem 1rem;
    font-family: "WorkSans-Medium";
    width: 280px;
}
.tostArrow {
    position: absolute;
    margin-top: 15px;
    margin-left: 101px;
    color: #2E3949;
    z-index: 10;
}
.tostMessageDiv {
    position: absolute;
    background: #2E3949;
    margin-top: 25px;
    /* margin-left: 50px; */
    color: white;
    width: 200px;
    text-align: left;
    padding: 4px;
    border-radius: 5px;
    z-index: 10;
}
.tostMessageDiv p{
    margin: 1px;
}
.tostMessageDiv span{
    color: #B4B4B4;
}
.tostArrow1 {
    position: absolute;
    /* margin-top: 15px;
    margin-left: 101px; */
    color: #2E3949;
    margin-bottom: 48px;
    transform: rotate(180deg);
    z-index: 10;
}
.tostMessageDiv1 {
    position: absolute;
    background: #2E3949;
    /* margin-top: 25px; */
    /* margin-left: 50px; */
    margin-bottom: 100px;
    color: white;
    width: 300px;
    text-align: left;
    padding: 8px;
    padding-bottom: 15px;
    border-radius: 5px;
    z-index: 10;
}
.tostMessageDiv1 p{
    margin: 1px;
}
.tostMessageDiv1 span{
    color: #B4B4B4;
}

.fullModelCss .modal-dialog-centered ,.fullModelCss .modal-dialog{
    min-width: 100vw !important;
    margin: 0px !important;
}
.fullModelCss .modal-content{
    min-height: 100vh !important;
}

.closeImgIconAddImage{
    position: absolute;
    right: 30px;
    bottom: 30px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
}

.next-position{
    position: absolute !important;
    white-space: nowrap;
}

@media (max-width:2060px) {
    .imageUploadDiv , .imageShowDiv{
        width: 50%;
    }
}

@media (max-width:1810px) {
    .imageUploadDiv , .imageShowDiv{
        width: 55%;
    }
}

@media (max-width:1440px) {
    .imageUploadDiv , .imageShowDiv{
        width: 65%;
    }
    .uploadImageTitle , .uploadImageSubTitle{
        width: 50%;
    }
}

@media (max-width:1240px) {
    .imageUploadDiv , .imageShowDiv{
        width: 75%;
    }
}

@media (max-width:1024px) {
    .imageUploadDiv , .imageShowDiv{
        width: 75%;
    }
    .uploadImageTitle , .uploadImageSubTitle{
        width: 60%;
    }
}

@media (max-width:976px) {
    .uploadImageDiv {
        width: 240px;
        height: 180px;
        margin: 20px;
    }
    .uploadedimage{
        height: 180px !important;
        width: 240px !important;
    }
}
@media (max-width:769px) {
    .uploadImageDiv {
        width: 160px;
        height: 160px;
        margin: 20px;
    }
    .uploadedimage{
        height: 160px !important;
        width: 160px !important;
    }
    .uploadImageTitle , .uploadImageSubTitle {
        width: 70%;
    }
    .HostaAILogoDiv{
        text-align: center;
    }
    .next-position{
        top: -1% !important;
    }
}

@media (max-width:600px) {
    .imageShowDiv{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
    }
    .uploadImageTitle , .uploadImageSubTitle {
        text-align: left;
        width: 95%;
    }
     .CenterText{
        margin-left: 20px !important;
        margin-right: 20px !important;
        text-align: left;
    }
    .imageUploadDiv{
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow: scroll;
        width: 95%;
        margin: unset !important;
    }
    .singleimageDiv{
        margin: 8px;
    }
}

@media (max-width:356px){
    .next-position a{
        margin: 0px !important;
    }
}