#new-claim-popup1 {
  
  background-color: $white;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 100vh;

  .hide_number_icon::-webkit-outer-spin-button,
  .hide_number_icon::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  .hide_number_icon {
    -moz-appearance: textfield; /* Firefox */
  }

  .main-address-popup {
    box-shadow: 0 3px 6px $color029;
    border-radius: 14px;
    width: 900px;
    background-color: $white;
    justify-self: center;
    position: relative;

    @media (max-width: $md) {
      width: 350px;
    }

    .form_newclaim {
      margin-bottom: 14px;
    }

    .closeImage {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      padding-top: 5px;
    }

    .left_form {
      margin-top: 0px;

      @media (max-width: $sm) {
        padding: 1rem;
        margin-top: 2px;
      }
    }

    .main_form_div {
      padding: 1rem;

      .both_form {
        display: flex;
        flex-direction: row;

        @media (max-width: $md) {
          flex-direction: column;
        }
      }

      .input-labels {
        font-family: $lato;
        color: $header;
        display: flex;
        flex-direction: column;
        font-size: 25px;
        margin-bottom: 3px !important;
        margin-right: 3rem;
      }

      .input-text {
        border: none;
        padding-left: 13px;
        border-radius: 6px;
        height: 42px;
        font-size: 28px;
        background-color: $colorE7;

        @media (max-width: $xs), (max-width: $md), (max-width: $lg) {
          //width: 367px;
        }

        @media (max-width: 768px) {
          width: 100% !important;
        }
      }

      .new-input {
        @media (max-width: $xs) {
          //width: 155px !important;
        }

        @media (max-width: $md), (max-width: $lg) {
          //width: 202px;
        }

        @media (max-width: 768px) {
          width: 100% !important;
        }
      }

      .input-text::placeholder {
        color: $black;
      }

      .select-labels {
        color: $blue-light;
        font-family: $lato;
        font-size: 30px;
        display: flex;

        @media (max-width: 768px) {
          width: 100%;
          display: block;
        }
      }

      .select-input {
        margin-left: 55px;
        height: 41px;
        align-items: center;
        background-color: $colorE7;
        border-radius: 6px;
        border: none;
        padding-left: 5px;
        color: $black;
        font-size: 28px;
        min-width: 49%;

        @media (max-width: 768px) {
          width: 100%;
          display: block;
          margin: 0;
        }

        option {
          font-size: 28px;
          color: $black;
        }
      }

      .completeaddress {
        margin-top: 51px;

        @media (max-width: $sm) {
          margin-top: 20px;
        }
      }

      .countryCodeDiv {
        input {
          font-size: 21px;
        }

        @media (max-width: 768px) {
          width: 100% !important;
        }
      }

      .cityCodeinner {
        display: flex;
        margin-top: 14px;

        .cityCode {
          margin: 0 23px 0 0;
          width: 36%;

          @media (max-width: $md) {
            width: 37%;
          }

          @media (max-width: 768px) {
            width: 100% !important;
          }
        }

        .selectcity {
          width: 100%;
          height: 41px;
          align-items: center;
          background-color: $colorE7;
          border-radius: 6px;
          border: none;
          padding-left: 5px;
          color: $black;
          font-family: $lato-bold;
          font-size: 28px;

          @media (max-width: $lg) {
            //width: 147px;
          }

          @media (max-width: $md) {
            width: 147px;
          }

          @media (max-width: 768px) {
            width: 100% !important;
          }

          option {
            font-size: 28px;
            color: $black;
            font-family: $lato-bold;
          }
        }
      }

      .heading-labels {
        color: $header;
        font-size: 35px;
        font-family: $lato-bold;

        @media (max-width: $sm) {
          padding: 1rem;
          text-align: center;
        }
      }

      .right_form {
        display: flex;
        flex-direction: column;
        right: 0;
        //bottom: 0;
        margin: 23px;
        //bottom: -13px;
        position: absolute !important;
        bottom: 0;

        @media (max-width: $md), (max-width: $lg) {
          /* right: 34px; */
          // position: static !important;
          display: flex !important;
          
          // margin: 0 !important;
          // bottom: -13px;
          right: 28px;
          bottom: 9px;
        }

        @media (max-width: $xl), (max-width: $xxl) and (max-width: $xxxl) {
          position: absolute !important;
          flex-direction: column;
          right: 28px;
          bottom: 9px;
        }

        @media (max-width: 768px) {
          position: relative !important;
          flex-direction: column;
          right: unset !important;
          bottom: 0 !important;
        }

        // @media (max-width: $sm) {
        //   position: inherit !important;
        // }
      }
    }

    .go_back {
      color: $header;
      font-size: 20px;
      margin-left: 7px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      @media (max-width: $md) {
        //position: absolute;
        top: 0;
        margin-top: 7px;
        left: -9px;
      }
    }

    .new_claims_btn_a {
      margin-bottom: 20px;
      padding: 2rem 2.5rem;
      font-family: $lato-bold;

      p {
        padding-left: 18px;
      }

      @media (max-width: $md) {
        margin-right: 10px;
        margin-bottom: 10px !important;
        padding: 1.5rem 6.3rem !important;
      }

      @media (max-width: $xs), (max-width: $sm) {
        padding: 1.5rem 6.3rem !important;
      }
    }

    .new_claims_btn_b {
      margin-bottom: 20px;
      padding: 2rem 2.5rem;
      justify-content: center;

      @media (max-width: $md) {
        margin-right: 10px;
        margin-bottom: 10px !important;
        justify-content: center;
        margin-top: 10px;
      }

      @media (max-width: $xs), (max-width: $sm) {
        //padding: 1.5rem 6.3rem !important;
        justify-content: center;
        margin: 15px;
      }
    }

    .btn_hover_text {
      display: none;
      position: absolute;
      bottom: 0px;
      margin-bottom: -35px;
    }
    .new_claims_btn:hover + .btn_hover_text {
      display: block !important;
    }
  }
}
.select-input {
  width: 130px;
  height: 40px;
  align-items: center;
  background-color: $colorE7;
  border-radius: 6px;
  border: none;
  padding-left: 5px;
  color: $black;
  font-size: 23px;
  margin-bottom: 15px;

  option {
    color: $black;
  }

  @media (max-width: $sm), (max-width: $xs) {
    width: 168px;
    font-size: 28px;
  }
}

.large-select-input {
  width: 250px;
  // margin-left: 21px;
}
.input-text-apt {
  border: none;
  margin-left: 60px;
  border-radius: 6px;
  height: 42px;
  font-size: 28px;
  background-color: $colorE7;
  // padding-left: 10px;
  padding-right: 35px;

  @media (max-width: $md) {
    width: 240px;
  }

  @media (max-width: $sm) {
    width: 332px;
    margin: auto;
  }
}
.room-type {
  margin-top: 25px;
  font-size: 14px;
}
// handling labels
.heading-labels {
  color: $header;
  font-size: 35px;
  width: 100%;
  padding-bottom: 30px;
  font-family: $lato-bold;

  @media (max-width: $sm), (max-width: $xs) {
    font-size: 45px;
  }
}
.btn-position {
  position: relative;
}
.btn-next {
  font-size: 25px;
  // padding: 1.5rem 4rem;
  // margin-right: 0%;
  position: absolute;
  bottom: 15px;
  right: 60px;
  @media (max-width: $sm), (max-width: $xs) {
    font-size: 40px;

    position: absolute;
    // bottom: 2%;
    right: 2%;
  }

  // ;
  // @media (max-width: $sm), (max-width: $xs) {
  //   margin-right: 18px;
  //   padding: 0.5rem 4rem;
  // }

  // @media (max-width: $md) {
  //   margin-right: 18px !important;
  //   padding: 1.5rem 3rem !important;
  // }
}
.oneImage-bg {
  top: 368px;
  margin-left: 21px;
  width: 714px;
  height: auto;
  // background-color: #e7ecf6;
  // border: 1px solid #707070;
  border-radius: 20px;
  opacity: 1;
  margin-top: 10px;

  @media (max-width: $sm) {
    width: auto;
  }
}
.oneImage {
  left: 747px;
  top: 388px;
  margin-left: 120px;
  width: 469px;
  height: 348px;
  background: transparent url('../../../../../images/damage/1-05.png') 0% 0% no-repeat padding-box;
  opacity: 1;

  @media (max-width: $sm) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}
.btn-upload-img {
  margin-bottom: 50px;
  margin: 0 auto 20px;
  text-align: center;
  background-color: #F6C142 !important;
  p {
    color: black !important;
    @media (max-width: $sm) {
      font-size: 15px !important;
    }
  }
}
.title-one-img {
  font-size: 30px;
  font-family: $lato-bold;
  margin-left: 21px;
  margin-bottom: 10px;
  padding-right: 20px;
  color: #4285F4;
  // text-align: center;
  @media (max-width: $sm) {
    font-size: 27px;
  }
}
.bold-text {
  font-family: $lato-bold;
}
.underline-text {
  font-family: $lato-bold;
  text-decoration: underline;
}
.new-btn-next {
  border: 0;
  background-color: white;
  height: fit-content;
  display: flex;
  align-items: center;
  margin-right: 21px;
}
.next-icon {
  width: 40px;
  object-fit: contain;
  margin-top: 5px;
  margin-right: 10px;
}
.next-Txt {
  margin: unset;
  font-size: 16px;
}
.disable-icon {
  opacity: 0.5;
}
.list-style {
  margin: 0px 30px;
  margin-bottom: 70px;
  li {
    font-size: 16px;
    color: #616F83;
    margin-top: 10px;
    font-family: "WorkSans-Medium" !important;
    .break-line{
      display: none;
      @media (max-width: $sm) {
        display: block;
      }
    }
  }
}
.p-text {
  font-size: 16px;
  margin-left: 21px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.back-link-oneImage {
  margin-left: 21px;
  position: absolute;
}
.back-link{
  margin-left: 21px;
}
.skip-page-position {
  position: relative;
  padding-bottom: 60px;
  padding-top: 15px;
}
.skip-page {
  position: absolute;
  right: 25px;
  padding-bottom: 10px;
  // width:150px;
  // height:65px;
}
.finish-btn {
  position: absolute;
  right: 25px;
  // padding-bottom:60px;
  width: 150px;
  height: 65px;
}
.next-position {
  position: relative;
  padding-bottom: 30px;
  padding-top: 40px;
}
.next-twoimg {
  position: absolute;
  right: 25px;
  bottom: 10px;
}
.instruction-p {
  padding: 15px;
  font-size: 25px;
  text-align: left;
  font: normal normal normal 25px/30px Lato;
  letter-spacing: 0.78px;
  color: #537bb8;
  opacity: 1;
}

.twoImg {
  margin-left: 120px;
  width: 469px;
  height: 348px;
  background: url('../../../../../images/damage/1-06@2x.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.instruction-img1 {
  width: 313px;
  height: 215px;
  background: url('../../../../../images/instructions/Web_instructions-03.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.instruction-img2 {
  margin-left: 20px;
  // top: 30px;
  // right: 20px;
  width: 379px;
  height: 80%;
  background: url('../../../../../images/instructions/Web_instructions-01.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.ImageUploadBgTip{
  display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    width: 95% !important;

    @media (max-width:600px){
      justify-content: flex-start !important;
    }
}
.uploadImageTitleTip{
  @media (max-width:600px){
    justify-content: flex-start !important;
  }
}
.tip-image1 {
  width: 313px;
  height: 215px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width:600px){
    width: 300px;
    margin-right: 10px;
  }
}
.tip-image2{
  width: 250px;
  height: 115px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 15px;

  @media (max-width:600px){
    width: auto;
    margin-right: 10px;
  }
}
.tipStatus-image{
  width: 50px;
  height: 50px;
}
.two-tip-img{
  display: flex;

  @media (max-width:600px){
    flex-direction: column;
  }
}
.tipdivwithsign{
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width:600px){
    flex-direction: row;
  }
}

.two-img {
  position: relative;
}
.tenImg {
  left: 747px;
  top: 388px;
  margin-left: 120px;
  width: 469px;
  height: 348px;
  background: transparent url('../../../../../images/damage/1-07.png') 0% 0% no-repeat padding-box;
  opacity: 1;
}

.ten-img-back {
  margin-left: 21px;
  position: absolute;
  margin-top: 18px;
}

.bg-img-expander {
  margin: 30px 0px;
  top: 368px;
  margin-left: 21px;
  width: 714px;
  height: auto;
  // background: transparent url('../../../images/damage/Rectangle\ 154.svg') 0% 0% no-repeat padding-box;
  // background-color: #e7ecf6;
  // border: 1px solid #707070;
  border-radius: 20px;
  opacity: 1;
  @media (max-width: $sm) {
    width: auto;
  }
}

.imgList {
  display: flex;
  margin-bottom: 8px;
  flex-flow: row wrap;
  margin-right: 8px;
  width: 100%;
  height: auto;
  padding: 4px;
  z-index: 1000;
}
.uploadedimg {
  width: 155px;
  height: 155px;
  border-radius: 16px;
}
.img {
  margin: 8px;
  position: relative;
}
.closeImgIcon {
  position: absolute;
  top: 0px;
  right: 0px;
}

.closeImage-top {
  top: 5px;
  right: 5px;
  position: absolute;
}

