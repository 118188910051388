.sub_details--information_wrapper {
    background-color: $color680;
    padding: 1rem;
    margin-left: 19px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .form_group {
        display: flex;
        align-items: center;

        .information_title {
            margin: 0 1rem 0 0;
            font-family: $lato;
            font-size: 18px;
            color: $color8282;
            min-width: 10rem;

            @media (max-width: $sm) {
                min-width: 8rem;
            }
        }

        &:not(:last-child) {
            margin-bottom: .5rem;
        }
    }
}
