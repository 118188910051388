#interface-button--basic {
    display: flex;
    align-items: center;
    color: $black;
    background-color: $white;
    border: 0;
    box-shadow: 0 3px 6px $color029;
    padding: 1.2rem 1.5rem;
    border-radius: 12px;
    z-index: 1;

    span {
        margin-left: 1rem;
    }

    &:hover,
    &:active,
    &:focus {
        color: $black;
        background-color: $white;
    }
}
