#room-added {
    background-color: $white;
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 100vh;

    .popup-container {
        box-shadow: 0 3px 6px $color029;
        border-radius: 14px;
        // width: 650px;
        background-color: $white;
        justify-self: center;
        position: relative;
        width: 100VW!important;
        height: 100vh;
        margin: 0px;
        @media (max-width: 520px) {
            width: 100VW!important;
            height: 100vh;
            margin: 0px;
        }

        .closeImage {
            display: flex;
            justify-content: flex-end;
            padding-right: 10px;
            padding-top: 5px;
        }
    }

    .heading-labels {
        color: black;
        font-size: 25px;
        font-family: $lato-bold;
    }

    .content-labels {
        color: $header;
        font-size: 28px;
        font-family: $lato-italic;
        margin-left: 24px;

        @media (max-width: 520px) {
            font-size: 19px;
        }
    }

    .email-field-new {
        margin-top: 40px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        .input-label-new {
            // width: 300px;
            // margin-left: 48px;
            // margin-bottom: 89px;

            @media (max-width: 520px) {
                margin-left: 72px;
            }
        }

        .content-label-new {
            // width: 300px;
            margin-bottom: 20px;
        }

        .add_another_room {
            // padding: 1rem 3rem;
            margin-top: 10px;
            font-family: $lato-bold;
            min-width: 250px;
        }

        .complete_btn {
            // padding: 1rem 6.3rem;
            margin-top: 10px;
            font-family: $lato-bold;
            min-width: 250px;
        }
    }
    .reqLinkBtn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
