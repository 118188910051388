#room-interface {
    padding: 1rem 2rem;
    min-height: calc(100vh - 5rem);

    @media (max-width: $sm) {
        padding: 1rem;
    }

    .interface_top {
        display: inline-block;
        padding-bottom: 2rem;
        margin-left: 2rem;
        width: calc(50% - 0rem);
        margin-top: 50px;

        span {
            @media only screen and (min-width: 576px) and (max-width: 768px) {
                font-size: 24px;
            }
        }

        @media only screen and (min-width: 576px) and (max-width: 768px) {
            padding: 50px 10px 10px;
        }

        @media (max-width: $lg) {
            padding-top: 4rem;
            width: 100%;
        }

        @media (max-width: $md) {
            flex-direction: column;
            align-items: center;
        }

        .breadcrumb_parent {
            color: $blue-light;
            font-family: $lato;
            margin: 0;
            font-size: 18px;
            letter-spacing: 2.5px;
        }

        svg {
            vertical-align: bottom;
        }

        .breadcrumb_title {
            color: $blue-light;
            font-family: $lato-bold;
            margin: 0;
            font-size: 18px;
            letter-spacing: 2.5px;
        }
    }

    .room_interface_container {
        & > * {
            margin-bottom: 2rem;
        }

        .carousel_room_interface {
            display: flex;
            flex-direction: column;

            & > * {
                margin-left: 2rem;
                margin-right: 2rem;
            }

            @media (max-width: $md) {
                margin-bottom: 3rem;
            }
        }

        .summary_room_interface {
            & > * {
                margin-left: 2rem;
                margin-right: 2rem;

                @media (max-width: $lg) {
                    margin: 0 0 3rem 0;
                }
            }
        }
    }
}

.dd{
    color: #3d69aa;
    font-family: "Lato";
    margin: 0;
    font-size: 18px;
    letter-spacing: 2.5px;
    cursor: pointer;
}

.claimIDHeader{
    position: absolute;
      margin-top: -50px;
      color: #ffffff;
      margin-left: 20px;
  }