#req-image {
  background-color: $white;
  align-items     : center;

  @media (max-width: $sm) {
    align-items: flex-start;
  }

  justify-content: center;
  display        : flex;
  min-height     : 100vh;

  .popup-container,
  .blur-bg {
    box-shadow      : 0 3px 6px $color029;
    border-radius   : 14px;
    width           : 700px !important;
    background-color: $white;
    justify-self    : center;
    position        : relative;

    @media (max-width: $md) {
      width: 400px !important;
    }

    @media (max-width: $sm) {
      width : 400px !important;
      margin: 10px;
    }

    .closeImage {
      display        : flex;
      justify-content: flex-end;
      padding-right  : 10px;
      padding-top    : 5px;
    }

    .heading-labels {
      color      : $header;
      font-size  : 37px;
      font-family: $lato-bold;
    }

    .ReqImagebutton {
      margin: top 10px;
    }

    .input-text-main {
      margin-top   : 20px;
      margin-bottom: 100px;
      padding-right: 10px;

      .input-text {
        border          : none;
        padding-left    : 13px;
        border-radius   : 6px;
        height          : 50px;
        font-size       : 28px;
        background-color: $colorE7;
        width           : 100%;
        padding-right   : 20%;

        @media (max-width: 440px) {
          width: 100%;
        }

        @media (max-width: $md) {
          width: 100%;
        }
      }

      .input-text::placeholder {
        color: $black;
      }
    }

    .email-field {
      padding-left: 28px;
    }

    .reqImgBtn {
      position: absolute;
      bottom  : 0;
      right   : 0;
      padding : 0 11px 10px 0;
    }

    .reqImgBtn1 {
      margin-top: 35px;
      position  : absolute;

      right  : 0;
      padding: 0 11px 10px 0;
    }

    .copyBtn {
      position    : absolute;
      right       : 0;
      margin-right: 1rem;
      padding     : 2;
    }

    .go_back {
      padding-left  : 15px;
      padding-bottom: 11px;
    }
  }

  .blur-bg {
    -webkit-filter: blur(5px);
  }

  .new_claim_btns_ab {
    padding: 1.5rem 2rem;

    @media (max-width: $sm),
    (max-width: $xs) {
      padding: 1.5rem 1rem !important;
    }
  }


  .ReqLinkPopup {
    z-index : 100;
    position: absolute;
  }

  .errorDiv {
    color: red;
  }
}