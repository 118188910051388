.ReleaseNotes{
  background-color: #fff;
  min-height: calc(100vh - 5rem);
  padding : 0rem 4rem;

  @media (max-width: 768px) {
      padding : 0rem 0rem;
      }

  .form{
      background-color: lightgray;
      padding: 2rem;
      margin : 4rem 30% 1rem 30%;

      @media (max-width: 1400px) {
          margin : 4rem 25% 1rem 25%;        
        }
        @media (max-width: 992px) {
            margin : 4rem 15% 1rem 15%;
          }
      @media (max-width: 768px) {
          margin : 4rem 2rem 1rem 2rem;
          }
      .text-label{
          margin-bottom: 0.3rem;
          font-family: "WorkSans-Regular";
          font-weight: 700;
      }
      .text-input{
          padding: 5px;
          font-family: "WorkSans-Regular";
          width: 100%;
          margin-bottom: 1rem;
          border: 1px solid;
          border-style: groove;
      }
      .submitBtnDiv{
          text-align: center;

          .submitBtn{
              background-color: dodgerblue ;
              border: 0px solid;
              padding: 0.5rem 1rem;

              color: white;
              font-size: 16px;
              font-family: "WorkSans-Regular";
              border-radius: 3px;
              &:active {
                background:#ccd6eb;
              }

          }
      }
  }

  #releasenotes-title {
    font-size: 36px;
    margin-bottom: 1.4rem;
    margin-top: 6rem;
  }

  .releaseNotesDiv{
      padding: 2rem;
      padding : 4rem 2rem 1rem 2rem;
      font-family: "WorkSans-Regular";
      font-size: 16px;

      .individualSlack{
          margin-bottom: 80px;
      }

      p{
          margin-bottom: 5px;
      }
      ul{
          margin-left: 15px;
      }
      .deleteBtn{
        background-color: dodgerblue ;
        border: 0px solid;
        padding: 0.5rem 1rem;
        color: white;
        font-size: 16px;
        font-family: "WorkSans-Regular";
        border-radius: 3px;
        &:active {
          background:#ccd6eb;
        }
      }

  }
} 