.not-found-container {
  background-color: #6a9eea;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error-heading {
  font-size: 50px;
  font-weight: 600px;
  color: white;
}

.error-text {
  color: white;
  font-size: 20px;
}
