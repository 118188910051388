#interface-button--primary {
  color: $white;
  background-color: $primary;
  border: 0;
  box-shadow: 0 3px 6px $color029;
  border-radius: 7px;
  padding: 0.5rem 1rem;
  font-family: $roboto-medium;
  display: flex;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    color: $primary;
    background-color: $yellow;
  }

  p {
    margin: 0;
    color: $white;
    font-family: $lato-bold;
    font-size: 20px;
    width: 100%;
  }
}

button:disabled:hover#interface-button--primary {
  background-color: #6a9eea !important;
}
