#interface-details {
    height: 100%;

    .interface_details_top {
        position: absolute;
        top: -2.75rem;
        width: calc(100% - 3rem);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 1.2rem;

        p {
            font-family: $lato;
            color: $color4D;
            font-size: 25px;

            // &:first-child {
            //     min-width: 16.5rem;
            // }

            // &:nth-child(3) {
            //     transform: translateX(2.5rem);
            // }
        }
    }

    .interface_details_bottom {
        .details_root_container {
            cursor: pointer;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            .details_root_header {
                display: flex;
                align-items: center;
                background-color: $secondary;
                border-radius: 9px;
                z-index: 1;
                position: relative;
                padding: .75rem 1rem;
                color: $white;
                font-family: $lato-bold;

                &_active {
                    background-color: $tomato;

                    &.details_root_header--walls {
                        background-color: $green;
                    }

                    &.details_root_header--floors {
                        background-color: $yellow;
                    }
                }

                .details_root_title {
                    margin: 0 0 0 .75rem;
                    text-transform: capitalize;
                    font-size: 24px;
                }
            }

            .details_root_content {
                overflow: auto;

                &.details_root_content--walls {
                    .broken_list_header_active.broken_list_header_active_collapsed {
                        background-color: $light-green;
                    }

                    .sub_details--information_wrapper {
                        background-color: $light-green;
                    }
                }

                &.details_root_content--floors {
                    .broken_list_header_active.broken_list_header_active_collapsed {
                        background-color: $light_yellow;
                    }

                    .sub_details--information_wrapper {
                        background-color: $light_yellow;
                    }
                }
            }

            .details_root_sub_details {
                display: flex;
                flex-direction: column;
                margin: 0 1rem 0 30px;
                padding: .5rem 0 1rem;
                min-width: 30rem;
            }
        }
    }
}
