@tailwind base;
@tailwind components;
@tailwind utilities;

@import './abstract/vendor';
@import './abstract/variables';
@import './abstract/global';
@import './base/fonts';

// COMPONENTS
@import '../components/Header/Header';
@import '../components/EditInput/EditInput';
@import '../components/Buttons/PrimaryButton/PrimaryButton';
@import '../components/Buttons/BasicButton/BasicButton';
@import '../components/Checkbox/Checkbox';
@import '../components/Box/Box';
@import '../components/Select/Select';
@import '../components/Input/Input';
@import '../components/BrokenList/BrokenList';
@import '../components/BrokenList/Information/Information';

// PAGES
@import '../pages/Auth/Login/Login';
@import '../pages/Auth/ForgotPassword/ForgotPassword';
@import '../pages/app/Dashboard/Dashboard';
@import '../pages/app/NewClaim/NewClaim';
@import '../pages/app/NewRoom/NewRoom';
@import '../pages/app/RoomInterface/RoomInterface';
@import '../pages/app/RoomInterface/Layouts/Calculation/Calculation';
@import '../pages/app/RoomInterface/Layouts/Carousel/Carousel';
@import '../pages/app/RoomInterface/Layouts/Carousel/CarouselTabs/CarouselTabs';
@import '../pages/app/RoomInterface/Layouts/Details/Details';
@import '../pages/app/RoomInterface/Layouts/RequestPlan/RequestPlan';
@import '../pages/app/RoomInterface/Layouts/UploadImage/UploadImage';
@import '../pages/app/RoomInterface/Layouts/Variables/Variables';
@import '../pages/app/AllClaim/AllClaim';
@import '../pages/app/NewClaim/Layouts/AddressForm/AddressForm';
@import '../pages/app/NewClaim/Layouts/ReqImage/ReqImage';
@import '../pages/app/NewClaim/Layouts/RequestLink/RequestLink';
@import '../pages/app/AllRoom/AllRoom';
@import '../pages/app/AllRoom/Layout/Breadcrumb/Breadcrumb';
@import '../pages/app/NewRoom/Layouts/RoomAdded/RoomAdded';
@import '../pages/app/Instructions/Instructions';
@import '../pages/app/NotFound/NotFound';
