#interface-request {
    flex: 1;

    .request_box {
        padding: 2rem;
        text-align: center;
        height: 100%;

        .request_title {
            color: $color099;
            font-family: $roboto-light;
        }

        .request_button {
            margin: 1rem auto 0;
        }
    }
}
