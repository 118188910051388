#home {
    padding: 4.5rem 2rem 1rem 2rem;
    background-color: $colorF6;

    #barchart {
        flex: 1;

        .barchart_box {
            padding: 0;
            border-radius: 1rem;
            text-align: center;
            height: 100%;
            min-height: 330px;
        }
    }

    #information {
        flex: 1;

        .info_box {
            padding: 6%;
            text-align: center;
            height: 100%;
            min-height: 0;

            .info_title {
                color: #0009;
                margin-bottom: 0;
            }
        }
    }

    #map {
        flex: 1;

        .map_box {
            padding: 6%;
            text-align: center;
            height: 100%;
            min-height: 330px;

            .map_title {
                color: #0009;
                margin-bottom: 0;
            }
        }
    }

    .recent_claim_title {
        color: #0009;
        font-size: 32px;
    }

    .recent_claim_box {
        padding: 0;

        .card-header {
            padding: .75rem .5rem;
            background-color: #fff;
        }

        .rigtSideText {
            color: #74808b;

            h6,
            .h6 {
                font-size: 13px;
                margin-bottom: 3px;
            }

            p {
                font-size: 13px;
            }
        }

        h5,
        .h5 {
            margin-bottom: 0;
        }

        p {
            margin: 0;
        }
    }

    .recent_claim_footer {
        .new_claim_button:hover {
            background-color: #fff;
        }

        .new_claim_button {
            background-color: #bed1ed;
            color: #788593;
            font-weight: 600;
            border: 0;
            border-radius: 22px;
            box-shadow: 0 3px 6px #00000029;
            padding-left: .35rem;

            .btn_txt {
                float: right;
                margin-top: 3px;
                font-size: 14px;
            }
        }
    }
}
