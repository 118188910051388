#req-link {
    background-color: $white;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 25rem;

    .popup-container {
        box-shadow: 0 3px 6px $color029;
        border-radius: 14px;
        width: 400px;
        background-color: $white;
        justify-self: center;
        position: relative;

        .closeImage {
            display: flex;
            justify-content: flex-end;
            padding-right: 10px;
            padding-top: 5px;
        }

        .go_back {
            padding-left: 15px;
            padding-bottom: 11px;
        }
    }

    .heading-labels {
        color: $header;
        font-size: 37px;
        font-family: $lato-bold;
    }

    .email-field-new {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        .input-label-new {
            margin-bottom: 100px;
        }
    }
}
