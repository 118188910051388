#interface-variables {
    position: relative;

    .variable_box {
        background-color: $white;

        &--active {
            background-color: $colorEC;
        }

        .left_icon {
            position: absolute;
            top: .5rem;
            right: 2rem;
            cursor: pointer;
            z-index: 1;
        }

        .right_icon {
            position: absolute;
            top: .5rem;
            right: .5rem;
            cursor: pointer;
            z-index: 1;
        }

        .variable_form {
            & > * {
                &.col-12 {
                    @media (min-width: $md) {
                        flex: 0 0 50%;
                        max-width: 50%;
                        margin-right: 1rem;
                    }
                }

                &:last-child {
                    .form_group:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .form_group {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1.5rem;

                //&--half {
                //    justify-content: flex-start;
                //
                //    & > * {
                //        &:last-child {
                //            margin-left: 3rem;
                //        }
                //    }
                //}

                .form_title {
                    font-size: 25px;
                    font-family: $lato;
                    color: $primary;
                    margin: 0;
                    text-transform: capitalize;

                    @media (max-width: $sm) {
                        margin-right: 1rem;
                    }
                }

                .form_answer {
                    font-size: 18px;
                    font-family: $lato;
                    color: $black;
                    margin: 0;
                }
            }
        }
        .reportBtnWrapper{
            display: flex;
            justify-content: flex-end;
        }
        .save_container {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}


.summerysucc{
    .modal-content{
         top      : 50%;
    left     : 50%;
    transform: translate(-50%, -50%);
    width    : 50%;   
    }
}
.summary_room_interface{
    .input_inner{
        span{
            display: none;
        }
    }
}