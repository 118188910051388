@font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'), url('/assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('/assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Medium';
    src: local('Roboto Medium'), url('/assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Bold';
    src: local('Roboto Bold'), url('/assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('/assets/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato Italic';
    src: local('Lato Italic'), url('/assets/fonts/Lato-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato Bold';
    src: local('Lato Bold'), url('/assets/fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'WorkSans-Regular';
    src: url('/assets/fonts/WorkSans-Regular.eot');
    src: url('/assets/fonts/WorkSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/WorkSans-Regular.woff2') format('woff2'),
        url('/assets/fonts/WorkSans-Regular.woff') format('woff'),
        url('/assets/fonts/WorkSans-Regular.ttf') format('truetype'),
        url('/assets/fonts/WorkSans-Regular.svg#WorkSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WorkSans-Light';
    src: url('/assets/fonts/WorkSans-Light.eot');
    src: url('/assets/fonts/WorkSans-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/WorkSans-Light.woff2') format('woff2'),
        url('/assets/fonts/WorkSans-Light.woff') format('woff'),
        url('/assets/fonts/WorkSans-Light.ttf') format('truetype'),
        url('/assets/fonts/WorkSans-Light.svg#WorkSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WorkSans-ExtraBold';
    src: url('/assets/fonts/WorkSans-ExtraBold.eot');
    src: url('/assets/fonts/WorkSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/WorkSans-ExtraBold.woff2') format('woff2'),
        url('/assets/fonts/WorkSans-ExtraBold.woff') format('woff'),
        url('/assets/fonts/WorkSans-ExtraBold.ttf') format('truetype'),
        url('/assets/fonts/WorkSans-ExtraBold.svg#WorkSans-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WorkSans-Medium';
    src: url('/assets/fonts/WorkSans-Medium.eot');
    src: url('/assets/fonts/WorkSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/WorkSans-Medium.woff2') format('woff2'),
        url('/assets/fonts/WorkSans-Medium.woff') format('woff'),
        url('/assets/fonts/WorkSans-Medium.ttf') format('truetype'),
        url('/assets/fonts/WorkSans-Medium.svg#WorkSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WorkSans-SemiBold';
    src: url('/assets/fonts/WorkSans-SemiBold.eot');
    src: url('/assets/fonts/WorkSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/WorkSans-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/WorkSans-SemiBold.woff') format('woff'),
        url('/assets/fonts/WorkSans-SemiBold.ttf') format('truetype'),
        url('/assets/fonts/WorkSans-SemiBold.svg#WorkSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WorkSans-Bold';
    src: url('/assets/fonts/WorkSans-Bold.eot');
    src: url('/assets/fonts/WorkSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/WorkSans-Bold.woff2') format('woff2'),
        url('/assets/fonts/WorkSans-Bold.woff') format('woff'),
        url('/assets/fonts/WorkSans-Bold.ttf') format('truetype'),
        url('/assets/fonts/WorkSans-Bold.svg#WorkSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WorkSans-Black';
    src: url('/assets/fonts/WorkSans-Black.eot');
    src: url('/assets/fonts/WorkSans-Black.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/WorkSans-Black.woff2') format('woff2'),
        url('/assets/fonts/WorkSans-Black.woff') format('woff'),
        url('/assets/fonts/WorkSans-Black.ttf') format('truetype'),
        url('/assets/fonts/WorkSans-Black.svg#WorkSans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
