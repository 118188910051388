#allclaim {
  padding         : 2rem 2rem 1rem 2rem;
  background-color: #fff;
  min-height      : calc(100vh - 5rem);

  .table-curved {
    border-collapse: separate;
    border-spacing : 0 1em;
    font-family    : $roboto;
    color          : #415878;

    th {
      padding     : 1rem;
      color       : #6a9eea;
      border      : 0;
      padding-left: 0;

      p {
        cursor       : pointer;
        margin-bottom: -15px;
        width        : 80px;
        display      : table-caption;
      }

      // 2-Aug SCSS started 
      .search-text-main {
        margin-top   : 10px;
        margin-bottom: 10px;
        width: 100%;
        //padding-right: 10px;

        .search-text {
          border          : none;
          padding-left    : 13px;
          border-radius   : 6px;
          height          : 40px;
          font-size       : 14px;
          background-color: $colorE7;
          width           : 100%;
        }

        .search-text::placeholder {
          color: $black;
        }

        .searchBtn {
          //position: absolute;
          justify-content: center;
          align-items    : inherit;
          height         : 40px;
          width          : 40px;
          padding        : 1%;
          margin-right   : 1%;
        }
      }

      // 2-Aug SCSS ended
    }

    td {
      position        : relative;
      background-color: #e7ecf6;
      border-bottom   : 0;
      border-top      : 0;
      // padding: 1rem;
      padding-left    : 15px !important;

      b {
        color: #000;

        @media only screen and (min-width: 576px) and (max-width: 768px) {
          //font-size: 11px;
          margin-bottom: 5px;
        }
      }

      p {
        margin-top   : 0.8rem;
        margin-bottom: 0;
        color        : #000;

        @media only screen and (min-width: 576px) and (max-width: 768px) {
          //font-size: 12px;
          margin-top : 0;
          font-family: $roboto;
        }
      }

      .greenText {
        color      : #529d2a !important;
        font-weight: bold;
      }
    }

    td:first-child {
      border-radius: 15px 0 0 15px;
      padding      : 0 !important;
      padding-left : 5px !important;

      @media only screen and (min-width: 576px) and (max-width: 768px) {
        //font-size: 12px;
        padding-left: 10px !important;
      }
    }

    td:last-child {
      border-radius: 0 15px 15px 0;
      padding      : 3px;
    }

    tr:hover td {
      background-color: #dcdee1;
      cursor          : pointer;
      box-shadow      : none;
    }

    .activeTD {
      background-color: #6a9eea;

      b,
      svg,
      p {
        color: #fff;
      }
    }

    tr:hover .activeTD {
      background-color: #6a9eea;
      cursor          : pointer;
      box-shadow      : none;
    }

    @media only screen and (max-width: 500px) {
      zoom      : calc(100% / 2.4);
      margin-top: 120px;
    }
  }

  .fixedLeft {
    @media (min-width: $md) {
      // position: fixed;
    }

    @media (max-width: $md) {
      margin-top: 3rem;
    }

    @media only screen and (min-width: 576px) and (max-width: 768px) {
      text-align: center;
      display   : block;
      margin    : 0 !important;
      position  : relative;
    }
  }

  .fixedLeft_a {
    // width: 23rem;
    width        : 100%;
    border       : $color70 1px solid;
    border-radius: 7px;
    margin-bottom: 0;

    @media (min-width: $md) {
      // position: fixed;
      position: sticky;
      top     : 1rem;
    }

    @media (max-width: $md) {
      margin-top: 3rem;
    }
  }

  #btnSection {
    @media (min-width: $md) {
      margin-top : calc(15vh);
      margin-left: 5%;
    }

    @media only screen and (min-width: 576px) and (max-width: 768px) {
      margin-top : calc(30vh);
      margin-left: -3%;
    }

    .new_claims_btn {
      font-size: 25px;
      padding  : 1.4rem;
      width    : 100%;
      display  : block;

      @media only screen and (min-width: 576px) and (max-width: 768px) {
        width: auto;
      }
    }
  }

  .closeBtn {
    text-align  : right;
    margin-right: -11px;
    margin-top  : -11px;
  }

  .info_title {
    .input-box-Div {
      margin-top: 5px;
    }

    .input-labels {
      font-family   : $lato;
      color         : $header;
      display       : flex;
      flex-direction: column;
      font-size     : 18px;
      margin-bottom : 0 !important;
      margin-top    : 8px;
    }

    .input-text {
      border          : none;
      padding-left    : 8px;
      border-radius   : 6px;
      height          : 32px;
      font-size       : 16px;
      background-color: $colorE7;
      width           : 100%;
      margin-top      : 8px;

      @media (max-width: $xs),
      (max-width: $md),
      (max-width: $lg) {
        //width: 367px !important;
      }
    }

    .new-input {
      @media (max-width: $xs) {
        //width: 155px !important;
      }

      @media (max-width: $md),
      (max-width: $lg) {
        // width: 202px !important;
      }
    }

    .input-text::placeholder {
      color: $black;
    }

    .notes_txt {
      height: 60px;
    }

    .select-labels {
      color         : $blue-light;
      font-family   : $lato;
      font-size     : 18px;
      display       : flex;
      flex-direction: column;
      margin-bottom : 5px !important;
    }

    .select-input {
      width           : 150px;
      height          : 32px;
      align-items     : center;
      background-color: $colorE7;
      border-radius   : 6px;
      border          : none;
      padding-left    : 5px;
      color           : $black;
      // font-family: $lato-bold;
      font-size       : 18px;

      @media (max-width: 520px) {
        width: 146px;
      }

      option {
        font-size    : 18px;
        color        : $black;
        //font-family: $lato-bold;
      }
    }

    .selectcity {
      width           : 100%;
      height          : 32px;
      align-items     : center;
      background-color: $colorE7;
      border-radius   : 6px;
      border          : none;
      padding-left    : 5px;
      color           : $black;
      font-family     : $lato-bold;
      font-size       : 18px;
      margin-top      : 8px;

      option {
        font-size  : 18px;
        color      : $black;
        font-family: $lato-bold;
      }
    }

    .heading-labels {
      color      : $color50;
      font-size  : 25px;
      font-family: $lato-bold;
    }

    .heading-label-border {
      border-bottom: 1px black solid;
    }

    .DropDownDamage {
      margin-top: 5px;
    }
  }

  #ClaimsPagination {
    .pagination {
      @media (min-width: $lg) {
        float: right;
      }

      @media (max-width: $md) {
        overflow-y: scroll;
      }
    }
  }
}

.page-item.active .page-link {
  z-index         : 3;
  color           : #fff;
  background-color: #537bb8;
  border-color    : #537bb8;
}

// #ClaimsTable tbody svg path
// {
//       fill: #9a9797;
// }

.margin-t {
  margin-top: -30px !important;

  @media only screen and (min-width: 576px) and (max-width: 768px) {
    margin-top: 0 !important;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: grey !important;
}

// input[placeholder], [placeholder], *[placeholder]
// {
//     color:green !important;
// }


.thidne {
  visibility: hidden;
}

#allclaim .table-curved th {
  padding-left: 15px;
}


.round {
  position: relative;
}

.round .label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round .label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #6a9eea;
  border-color: #6a9eea;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}