$spaceY: 3rem;
$spaceX: 3rem;
.modal-dialog-centered {
  @media (min-width: $xl) {
    max-width: 100% !important;
  }
  .iiz__img {
    @media (min-width: $xl) {
      width: 100%;
      height: 90vh;
    }
  }
  .arrow {
    .arrow-icon {
      width: 100px;
      height: 100px;
    }
  }
  @media (min-width: $md) {
    .arrow-right {
      // right: 16%;
      right: 10%;
    }
    .arrow-left {
      // left: 16%;
      left: 10%;
    }
  }
  @media (max-width: $md) {
    .arrow {
      .arrow-icon {
        width: 100px;
        height: 100px;
      }
    }
    .arrow-right {
      right: 0;
    }
    .arrow-left {
      left: 0;
    }
  }
}

#interface-carousel {
  padding-bottom: #{$spaceY};
  margin-bottom: 50px;

  .bootstrap_carousel {
    #img-mapper {
      margin: 0 auto;
    }

    img {
      width: 100%;
      object-fit: contain;
      object-position: center;
      height: auto;
    }

    .carousel-indicators {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 #{-$spaceY} 0;

      & > * {
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background-color: $color82;

        &.active {
          width: 20px;
          height: 20px;
        }
      }
    }

    .carousel-control-prev {
      width: $spaceX;
      left: -$spaceX;
    }

    .carousel-control-next {
      width: $spaceX;
      right: -$spaceX;
    }
  }
}

.preview-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  position: absolute;
  right: 35px;
  cursor: pointer;
  z-index: 111;
  p {
    font-size: 18px;
    font-family: 'Lato';
    color: #000;
    margin: 0;
  }
}

// .modal-dialog {
//   max-width: 100vw !important;
// }

.preview-img-modal {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  // margin-left: 5px;
  //   width: 100vw;
  .image-wrapper {
    border: 5px solid white;
    div {
      img {
        width: auto !important;
      }
    }
  }
  @media (max-width: $sm) {
    .image-wrapper {
      width: 75%;
    }
  }
}

.arrow {
  cursor: pointer;
  position: fixed;
  z-index: 1;
}

.bottom-wrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  align-self: center;
  width: 100%;
  justify-content: center;
}

.zoom-btn {
  margin: 0px 10px;
  cursor: pointer;
  font-size: 20px;
}


.modal-dialog-centered .arrow .arrow-icon {
  width : 60px !important;
  height: auto !important;
    @media (min-width: $xl) {
    width : 75px !important;
  }
}