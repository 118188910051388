#new-claim {
  // background-color: $white;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 100vh;

  .go_back {
    color: $header;
    font-size: 20px;
    margin-left: 7px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    @media (max-width: $md) {
      //position: absolute;
      top: 0;
      margin-top: 7px;
      left: -9px;
    }
  }
  .bgImg {
    min-height: 100vh;
    width: 100vw;
    position: fixed;
    background-image: url("../../../assets/images/bgImgAllClaim.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-filter: blur(7px);
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .hide_number_icon {
    -moz-appearance: textfield; /* Firefox */
  }

  .hide_number_icon::-webkit-outer-spin-button,
  .hide_number_icon::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  .input-box-Div {
    align-items: left;
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
  }

  .form-newclaim {
    box-shadow: 0 3px 6px $color029;
    border-radius: 14px;
    width: 900px;
    background-color: $white;
    justify-self: center;
    position: relative;

    @media (max-width: $sm),
    (max-width: $xs) {
      width: 369px !important;
    }

    @media (max-width: $md) {
      width: 553px;
    }

    .closeImage {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      padding-top: 5px;
    }

    .main_form_div {
      @media (max-width: $sm) {
        min-width: 320px;
      }
      
      @media (max-width: $md) {
        width: 481px;
      }
      min-width: 379px;
      padding: 0 0 75px 21px;
      
      
      .new_claims_btn_b {
        margin-bottom: 20px;
        padding: 2rem 2.5rem;
        justify-content: center;
  
        @media (max-width: $md) {
          margin-right: 10px;
          margin-bottom: 10px !important;
          justify-content: center;
          margin-top: 10px;
        }
  
        @media (max-width: $xs), (max-width: $sm) {
          //padding: 1.5rem 6.3rem !important;
          justify-content: center;
          margin: 15px;
        }
      }
  
      .btn_hover_text {
        display: none;
        position: absolute;
        bottom: 0px;
        margin-bottom: -35px;
      }
      .new_claims_btn:hover + .btn_hover_text {
        display: block !important;
      }

      .right_form {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        
        // right: 0;
        
        // height: 20%;

        //bottom: 0;
        // margin: 23px;
        //bottom: -13px;
        // position: absolute !important;
        //bottom: 0;
        

        // @media (max-width: $sm) {
        //   min-width: 320px;
      
        // }
        // @media (min-width: $md) {
        //   display: flex;
        //   flex-direction: column;
        //   right: 0;
          // position: absolute !important;
          // right: 28px;
          // bottom: 9px;
        

        @media (min-width: lg) {
          margin-left: 20%;
          padding-top: 20%;
          // margin-left: 30%;
          // margin: 23px;
          // bottom: 0px;
          // position: absolute !important;
          
          
  
          
        }
        // @media (min-width: $md) {
          // min-width: 481px;
          // width:85%;
          // media (max-width: 768px) {
          //   position: relative !important;
          //   flex-direction: column;
          //   right: 28px;
          //   bottom: 9px;
            // right: unset !important;
            // bottom: 0 !important;
          // }
        // }
          


        // @media (max-width: $lg) {
          /* right: 34px; */
          // position: static !important;
          
          
          // display: flex !important;
          // margin: 0 !important;
          // right: 28px;
          // bottom: 9px;


          // bottom: -13px;
        // }

        // @media (max-width: $xl), (max-width: $xxl) and (max-width: $xxxl) {
         
        //   position: absolute !important;
        //   flex-direction: column;
        //   right: 28px;
        //   bottom: 9px;

        // }

        // @media (max-width: $sm) {
        //   position: inherit !important;
        // }
      }

      

      .cityCodeinner {
        display: flex;
        margin-top: 14px;
        width: 100%;
        
        .cityCode {
          margin: 0 23px 0 0;
          width: 36%;

          // @media (max-width: $md) {
          //   width: 37%;
          // }

          // @media (max-width: 768px) {
          //   width: 100% !important;
          // }
        
          .selectcity {
            width: 100%;
            height: 100%;
            align-items: center;
            background-color: $colorE7;
            border-radius: 6px;
            border: none;
            padding-left: 5px;
            color: $black;
            font-family: $lato-bold;
            font-size: 24px;

            @media (max-width: $lg) {
              //width: 147px;
            }

            @media (max-width: $md) {
              width: 147px;
            }

            @media (max-width: 768px) {
              width: 100% !important;
            }

            option {
              font-size: 28px;
              color: $black;
              font-family: $lato-bold;
            }
          }
        }    
        
        .countryCodeDiv {
          width: 70%;
        

          .new-input {
            width: 100%;
            @media (max-width: $xs) {
              //width: 155px !important;
            }

            @media (max-width: $md), (max-width: $lg) {
              //width: 202px;
            }

            // @media (max-width: 768px) {
            //   width: 100% !important;
            // }
          }
        }
      }

      .both_form {
        display: flex;
        flex-direction: row;

        @media (max-width: $md) {
          width: 300px;
          flex-direction: column;
        }
      }
      .left_form {
        margin-top: 0px;
        width: 50%;
        @media (max-width: $sm) {
          // padding: 1rem;
          margin-top: 2px;
          min-width: 100%;
        }

        @media (max-width: $md) {
          // padding-right: .5rem;
          margin-top: 2px;
          min-width: 100%;
        }

        @media (min-width: $md) {
          margin-right: 20%;
        }
      }

      .completeaddress {
        margin-top: 51px;

        @media (max-width: $sm) {
          margin-top: 20px;
        }
      }

      .input-labels {
        font-family: $lato;
        color: $header;
        display: flex;
        flex-direction: column;
        font-size: 30px;
        margin-bottom: 3px !important;          
        // margin-right: 3rem;
        font-size: 22px;

        @media (max-width: $md) {
          // margin-right: 1rem;
        }
      }    

      .input-text {
        border: none;
        padding-left: 13px;
        border-radius: 6px;
        height: 42px;
        font-size: 28px;
        background-color: $colorE7;

        @media (max-width: $xs), (max-width: $md), (max-width: $lg) {
          //width: 367px;
        }

        @media (max-width: 768px) {
          width: 100% !important;
        }
      }

      .input-textadd {
        border: none;
        padding-left: 13px;
        border-radius: 6px;
        height: 42px;
        font-size: 28px;
        background-color: $colorE7;
      

        @media (max-width: $md) {
          font-size: 22px;
        }
        @media (max-width: $xs) {
          font-size: 22px;
        }

        @media (max-width: 768px) {
          width: 100% !important;
        }
      }


      .input-text,
      .input-text1 {
        border: none;
        padding-left: 11px;
        border-radius: 6px;
        height: 38px;
        font-size: 14px;

        @media (max-width: $md) {
          width: 240px;
        }

        @media (max-width: $sm) {
          width: 332px;
        }
      }

      .input-text::placeholder {
        color: $black;
      }

      .input-text1 {
        border: 1px solid hsl(0, 0%, 80%);
        margin-left: 20px;
        width: 50%;
        padding-right: 0;

        @media (max-width: $md) {
          width: 240px;
          margin-left: 0px !important;
        }

        @media (max-width: $sm) {
          width: 332px;
          margin: auto;
          margin-left: 0px !important;
        }
      }

      .input-text2 {
        border: 1px solid hsl(0, 0%, 80%);
        margin-left: 30px;
        width: 50%;
        padding-right: 35px;
        padding-bottom: 20px;
        padding-top: 15px;
       

        padding-left: 11px;
        border-radius: 6px;
        height: 38px;
        font-size: 14px;

        @media (max-width: $md) {
          width: 240px;
        }

        @media (max-width: $sm) {
          width: 332px;
          margin: auto;
          margin-left: 0px;
        }
      }

      .input-text::placeholder {
        color: $black;
      }

      .select-labels {
        color: $color31;
        font-family: $lato;
        font-size: 26px;
        display: flex;

        @media (max-width: $sm) {
          display: flex;
          flex-direction: column;
        }
      }

      .select-input {
        margin-left: 3.8rem;
        height: 41px;
        align-items: center;
        background-color: $colorE7;
        border-radius: 6px;
        border: none;
        padding-left: 5px;
        color: $black;
        font-family: $lato-bold;
        font-size: 26px;

        @media (max-width: $md) {
          margin-left: 1.8rem;
        }

        @media (max-width: $sm) {
          margin-left: 0;
          width      : 332px;
        }

        option {
          font-size    : 28px;
          color        : $black;
          //font-family: $lato-bold;
        }
      }

      .large-select-input {
        width: 25%;
      }

      .heading-labels {
        color      : $header;
        font-size  : 28px;
        font-family: $lato-bold;
      }

      .heading-labels-manual {
        color      : $header;
        font-size  : 28px;
        font-family: $lato-bold;
      }

      .propertyDamageDiv {
        padding-top   : 30px;
        display       : flex;
        flex-direction: column;

        .input-box-Div {
          display: flex;
          flex-direction: row;
          margin-bottom : 3px;

          @media (max-width: $sm) {
            display       : flex;
            flex-direction: column;
            margin-bottom : 3px;
            align-items   : flex-start;
          }
        }

        .damageselect {
          height    : 39px;
          margin-top: 4px;
        }

        .enteraddLink {
          margin-top: 30px;
          font-size : 14px;
        }
      }

      .both_button {
        margin-right   : 20px;
        margin-top     : 85px;
        display        : flex;
        flex-direction : row;
        justify-content: space-evenly;
        position       : relative;

        @media (max-width: $sm) {
          flex-direction: column;
          margin-right  : 0;
        }

        .new_claims_btn_1 {
          font-size   : 25px;
          padding     : 1.8rem 5.5rem;
          margin-right: 18px;

          p {
            text-align: center !important;
            width     : 100% !important;
          }

          @media (max-width: $sm),
          (max-width: $xs) {
            margin-right : 18px;
            padding      : 0.5rem 3rem;
            margin-bottom: 14px;
          }

          @media (max-width: $md) {
            margin-right: 18px !important;
            padding     : 1.5rem 3rem !important;
          }
        }

        .new_claims_btn_2 {
          font-size   : 25px;
          padding     : 1.5rem 4rem;
          margin-right: 18px;

          p {
            text-align: center;
            width     : 100%;
          }

          @media (max-width: $sm),
          (max-width: $xs) {
            margin-right: 18px;
            padding     : 0.5rem 4rem;
          }

          @media (max-width: $md) {
            margin-right: 18px !important;
            padding     : 1.5rem 3rem !important;
          }
        }
      }

      .btn_txt {
        margin-top: 0;

        .btn_hover_text {
          display        : none;
          justify-content: flex-end;
          margin-right   : 60px;
          color          : #505b6b;
          font-size      : 20px;

          p {
            font-size: 16px;
            position : relative;
            bottom   : -30px;
          }
        }

        .btn_hover_text1 {
          display        : none;
          justify-content: flex-end;
          margin-right   : 60px;
          color          : #505b6b;
          font-size      : 20px;

          p {
            font-size: 16px;
            position : relative;
            bottom   : -30px;
          }
        }

        .new_claims_btn_2:hover+.btn_hover_text {
          display : block !important;
          position: absolute;
          width   : 234px;
          right   : 90px;
          top     : -6rem;
        }

        .new_claims_btn_1:hover+.btn_hover_text1 {
          display : block !important;
          position: absolute;
          left    : 80px;
          width   : 234px;
          top     : -6rem;
        }
      }
    }

    .errorDiv {
      color       : red;
      padding-left: 13rem;

      @media (max-width: $md) {
        padding-left: 0 !important;
      }
    }
  }

  .css-2b097c-container {
    width: 50%;

    @media (max-width: 576px) {
      width: 100% !important;
    }
  }

  .error-left {
    margin-left: 11rem;

    @media (max-width: 576px) {
      margin-left: 0 !important;
    }

    @media (max-width: 768px) {
      margin-left: 11rem;
    }
  }

  .DropDownDamage {
    margin-top: 15px;
  }

  .title-one-img {
    font-size: 40px;
  }
}

.select-input {
  width           : 130px;
  height          : 40px;
  align-items     : center;
  background-color: $colorE7;
  border-radius   : 6px;
  border          : none;
  padding-left    : 5px;
  color           : $black;
  font-size       : 23px;
  margin-bottom   : 15px;

  option {
    color: $black;
  }

  @media (max-width: $sm),
  (max-width: $xs) {
    width    : 168px;
    font-size: 28px;
  }
}

.large-select-input {
  width: 250px;
}

.input-text-apt {
  border          : none;
  margin-left     : 50px !important;
  border-radius   : 6px;
  height          : 42px;
  font-size       : 28px;
  background-color: $colorE7;
  // padding-left: 10px;
  padding-right   : 35px;

  @media (max-width: $md) {
    width: 240px;
  }

  @media (max-width: $sm) {
    width      : 332px;
    margin     : auto;
    margin-left: 0px !important;
  }
}

.room-type {
  margin-top: 25px;
  font-size : 14px;
}

// handling labels
.heading-labels {
  color         : $header;
  font-size     : 35px;
  width         : 100%;
  padding-bottom: 30px;
  font-family   : $lato-bold;

  @media (max-width: $sm),
  (max-width: $xs) {
    font-size: 45px;
  }
}

.btn-position {
  position: relative;
}

.btn-next {
  font-size      : 25px;
  // padding: 1.5rem 4rem;
  // margin-right: 0%;
  position       : absolute;
  bottom         : 15px;
  right          : 60px;

  @media (max-width: $sm),
  (max-width: $xs) {
    font-size: 40px;

    position : absolute;
    // bottom: 2%;
    right    : 2%;
  }

  .btn_next_hover {
    display        : flex;
    justify-content: flex-end;
    // margin-right: 60px;
    // color: #505b6b;
    font-size      : 20px;

    p {
      font-size: 16px;
      position : relative;
      // bottom: -30px;
      bottom   : 3px;
      right    : 3px;
    }
  }

  // ;
  // @media (max-width: $sm), (max-width: $xs) {
  //   margin-right: 18px;
  //   padding: 0.5rem 4rem;
  // }

  // @media (max-width: $md) {
  //   margin-right: 18px !important;
  //   padding: 1.5rem 3rem !important;
  // }
}

.oneImage-bg {
  text-align         : center;
  top                : 368px;
  margin-left        : 21px;
  width              : 714px;
  height             : auto;
  // background-color: #e7ecf6;
  // border: 1px solid #707070;
  border-radius      : 20px;
  opacity            : 1;
  text-align         : center;

  @media (max-width: $sm) {
    width: auto;
  }
}

.oneImage {
  left       : 747px;
  top        : 388px;
  margin-left: 120px;
  width      : 469px;
  height     : 348px;
  background : transparent url("../../../images/damage/1-05.png") 0% 0% no-repeat padding-box;
  opacity    : 1;

  @media (max-width: $sm) {
    display     : block;
    margin-left : auto;
    margin-right: auto;
    width       : 50%;
  }
}

.btn-upload-img {
  margin-bottom: 50px;
  margin       : 0 auto 20px;
  text-align   : center;
}

.title-one-img {
  font-size           : 25px;
  margin-left         : 21px;
  // @media (max-width: $sm) {
  //   font-size: 21px !important;
  // }
}

.p-text {
  font-size    : 16px;
  margin-left  : 21px;
  margin-top   : 10px;
  margin-bottom: 10px;
}

.p-cursor {
  cursor: pointer;
}

.back-link-oneImage {
  margin-left: 21px;
  position   : absolute;
  cursor     : pointer;
}

.skip-page-position {
  position      : relative;
  padding-bottom: 60px;
  padding-top   : 15px;
  cursor        : pointer;
}

.skip-page {
  position      : absolute;
  right         : 25px;
  padding-bottom: 10px;
  // width:150px;
  // height:65px;
}

.finish-btn {
  position         : absolute;
  right            : 25px;
  // padding-bottom:60px;
  width            : 150px;
  height           : 65px;
}

.next-position {
  position      : relative;
  padding-bottom: 30px;
  padding-top   : 40px;
}

.next-twoimg {
  position: absolute;
  right   : 25px;
  bottom  : 10px;
}

.instruction-p {
  padding       : 15px;
  font-size     : 25px;
  text-align    : left;
  font          : normal normal normal 25px/30px Lato;
  letter-spacing: 0.78px;
  color         : #537bb8;
  opacity       : 1;
}

.twoImg {
  margin-left      : 120px;
  width            : 469px;
  height           : 348px;
  background       : url("../../../images/damage/1-06@2x.png");
  background-repeat: no-repeat;
  background-size  : cover;
}

.instruction-img1 {
  width            : 313px;
  height           : 215px;
  // background: url('../../../images/instructions/Web_instructions-03.png');
  background-repeat: no-repeat;
  background-size  : cover;
}

.instruction-img2 {
  margin-left      : 20px;
  // top: 30px;
  // right: 20px;
  width            : 379px;
  height           : 80%;
  // background: url('../../../images/instructions/Web_instructions-01.png');
  background-size  : cover;
  background-repeat: no-repeat;
}

.two-img {
  position: relative;
}

.tenImg {
  left       : 747px;
  top        : 388px;
  margin-left: 120px;
  width      : 469px;
  height     : 348px;
  background : transparent url("../../../images/damage/1-07.png") 0% 0% no-repeat padding-box;
  opacity    : 1;
}

.ten-img-back {
  margin-left: 21px;
  position   : absolute;
  margin-top : 18px;
  cursor     : pointer;
}

.bg-img-expander {
  top                : 368px;
  margin-left        : 21px;
  width              : 714px;
  height             : auto;
  // background: transparent url('../../../images/damage/Rectangle\ 154.svg') 0% 0% no-repeat padding-box;
  // background-color: #e7ecf6;
  // border: 1px solid #707070;
  border-radius      : 20px;
  opacity            : 1;
  text-align         : center;

  @media (max-width: $sm) {
    width: auto;
  }
}

.imgList {
  display      : flex;
  margin-bottom: 8px;
  flex-flow    : row wrap;
  margin-right : 8px;
  width        : 100%;
  height       : auto;
  padding      : 4px;
  z-index      : 1000;
}

.uploadedimg {
  width        : 155px;
  height       : 155px;
  border-radius: 16px;
}

.img {
  margin  : 8px;
  position: relative;
}

.closeImgIcon {
  position: absolute;
    right: 5px;
    top: 100px;
    background: white;
    border-radius: 50%;
}

.closeImage-top {
  top     : 5px;
  right   : 5px;
  position: absolute;
  cursor  : pointer;
}

@media (max-width: 576px) {
  .heading-labels {
    font-size: 24px;
  }

  #add-room .dx-theme-generic-typography a {
    color    : #337ab7;
    font-size: 21px;
    padding  : 0 !important;
  }

  .skip-page,
  .next-twoimg {
    right: 0 !important;
  }

  .oneImage,
  .twoImg,
  .instruction-img1,
  .instruction-img2 {
    background-size    : contain !important;
    margin             : 0 !important;
    width              : 100% !important;
    left               : 0 !important;
    top                : 0 !important;
    background-position: center;
  }
}

.f434 {
  width: 60%;

  @media (max-width: $sm) {
    width: 80%;
  }
}

.f435 {
  width: 30%;

  @media (max-width: $sm) {
    width: 80%;
  }
}

.vcenter {
  text-align: center;
}

.btn_next_hover,
.btn_next_hover1 {
  display     : none;
  width       : 150px;
  position    : absolute;
  right       : 0;
  margin-right: 15%;
  margin-top  : -20px;
}

.next-twoimg:hover+.btn_next_hover1 {
  display: block !important;
}
.css-2b097c-container{
  margin-left: 18px;
width      : 100%;
 @media (max-width: $sm) {
   margin-left: 0;
width      : 98%;
 }
}

.addimagesd{
  // display: inline-flex;
  .iimg1{
    @media (max-width: 768px) {
  width:100%;
  margin-left: 0;
  margin-top: 20px;

}
    width:200px;
  }
  .iimg2 {
    @media (max-width: 768px) {
  width      : 100%;
  margin-left: 0;
  margin-top : 20px;

}
    margin-left: 50px;
  width: 400px;
}
}

.f435ddd{
  width: 50%;
}